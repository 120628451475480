import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('toaster', ['setToaster']),
    errorToast(error) {
      const errMessage = error?.response?.data?.error;
      const toast = {
        title: this.$t('errorMessages.somethingWentWrong'),
        body: errMessage || this.$t('errorMessages.tryAgain'),
      };
      this.setToaster({
        type: 'toast-danger',
        toast,
      });
    },
  },
};
