<template>
  <div class="task-dropdown" v-click-outside="closeDropdown">
    <div class="download-answers-link" @click="showDropdown = !showDropdown">
      {{ $t('label.downloadAnswers') }}
      <DownloadIcon />
    </div>

    <div class="task-dropdown__dropdown" :class="{ show: showDropdown }">
      <div class="task-dropdown__input">
        <DropdownWithCheckBoxes
          id="d-courses"
          :showDropdown.sync="showCourses"
          :selectedCourseIds="selectedCourseIds"
          :isSelectedAllCourses="isSelectedAllCourses"
          :toggleCourses="toggleCourses"
          :toggleCourse="toggleSelectedCourseId"
          type="courses"
          disabled
          :label="$t('label.chooseCourse')"
          @close="showCourses = false"
        />
      </div>
      <div class="task-dropdown__input">
        <DropdownWithCheckBoxes
          id="d-tasks"
          :showDropdown.sync="showTasks"
          :selectedTaskIds="selectedTaskIds"
          :toggleTask="toggleSelectedTaskId"
          :toggleTasks="toggleTasks"
          :isSelectedAllTasks="isSelectedAllTasks"
          :selectedCourses="selectedCourses"
          :tasks="selectedTasks"
          type="tasks"
          :label="$t('label.chooseTasks')"
          disabled
        />
      </div>
      <div class="task-dropdown__action">
        <AddButton
          :title="$t('label.downloadAnswers')"
          size="small"
          :isDisabled="loading || !this.selectedTaskIds.length"
          @click="downloadAnswers"
        >
          <template #suffixIcon>
            <DownloadIcon />
          </template>
        </AddButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DownloadIcon from '@/components/Icons/DownloadIcon.vue';
import AddButton from '@/components/Buttons/AddButton.vue';
import DropdownWithCheckBoxes from '@/components/DropdownWithCheckBoxes.vue';
import ActivityService from '@/services/activity.service';
import errorToastMixin from '@/mixins/errorToast.mixin';

export default {
  name: 'TaskDropdown',
  components: { DropdownWithCheckBoxes, DownloadIcon, AddButton },
  mixins: [errorToastMixin],
  data: () => ({
    showDropdown: false,
    showCourses: false,
    showTasks: false,

    selectedCourseIds: [],
    selectedTaskIds: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({ chats: 'chats/getChatList', allCourses: 'chats/getFilteredCourses' }),
    selectedCourses() {
      return this.allCourses.filter((course) => this.selectedCourseIds.includes(course.id));
    },
    isSelectedAllCourses() {
      if (!this.allCourses.length) {
        return false;
      }
      return (
        this.allCourses.filter((course) => this.selectedCourseIds.includes(course.id)).length
        === this.allCourses.length
      );
    },

    allTasks() {
      return this.allCourses.reduce((acc, curr) => {
        acc.push(...curr.tasks);
        return acc;
      }, []);
    },
    allTasksOfSelectedCourses() {
      return this.selectedCourses.reduce((acc, curr) => {
        acc.push(...curr.tasks);
        return acc;
      }, []);
    },
    selectedTasks() {
      return this.allTasksOfSelectedCourses.filter((t) => this.selectedTaskIds.includes(t._id));
    },
    isSelectedAllTasks() {
      return this.allTasksOfSelectedCourses.length === this.selectedTaskIds.length;
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // eslint-disable-next-line no-param-reassign
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },
  methods: {
    closeDropdown() {
      if (this.showTasks || this.showCourses) {
        this.showTasks = false;
        this.showCourses = false;
        return;
      }

      this.showDropdown = false;
    },
    async downloadAnswers() {
      if (this.loading || !this.selectedTaskIds.length) {
        return;
      }
      this.loading = true;
      await ActivityService.getTaskAnswersFile(this.selectedTaskIds, this.$root.$i18n.locale)
        .then((response) => {
          const blob = response.data;
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'answers.xlsx');
          } else {
            const downloadLink = window.document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }));
            downloadLink.download = 'answers.xlsx';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          }
        })
        .catch(this.errorToast)
        .finally(() => (this.loading = false));
    },

    toggleSelectedCourseId(courseId) {
      const foundedIndex = this.selectedCourseIds.findIndex((id) => id === courseId);
      if (foundedIndex > -1) {
        const course = this.selectedCourses.find((c) => c.id === courseId);
        course.tasks.map((t) => {
          const foundedTagIndex = this.selectedTaskIds.findIndex((id) => id === t._id);
          if (foundedTagIndex > -1) {
            this.selectedTaskIds.splice(foundedTagIndex, 1);
          }
          return t;
        });
        this.selectedCourseIds.splice(foundedIndex, 1);
      } else {
        this.selectedCourseIds.push(courseId);
      }
    },
    toggleCourses(bool) {
      if (bool) {
        this.selectedCourseIds = this.allCourses.map((course) => course.id);
      } else {
        this.selectedCourseIds = [];
        this.toggleTasks(false);
      }
    },

    toggleSelectedTaskId(taskId) {
      const foundedIndex = this.selectedTaskIds.findIndex((id) => id === taskId);
      if (foundedIndex > -1) {
        this.selectedTaskIds.splice(foundedIndex, 1);
      } else {
        this.selectedTaskIds.push(taskId);
      }
    },
    toggleTasks(bool) {
      if (bool) {
        this.selectedTaskIds = this.selectedCourses.reduce((acc, curr) => {
          curr.tasks.map((t) => acc.push(t._id));
          return acc;
        }, []);
      } else {
        this.selectedTaskIds = [];
      }
    },
  },
  watch: {
    showCourses(bool) {
      if (bool) {
        this.showTasks = false;
      }
    },
    showTasks(bool) {
      if (bool) {
        this.showCourses = false;
      }
    },
    isSelectedAllCourses(bool) {
      if (bool) {
        this.toggleTasks(true);
      }
    },
  },
};
</script>
