<template>
  <div class="dropdown-with-checkboxes">
    <b-form-group
      :id="id"
      :label-for="id"
      :label-class="{
        focus: isFocused || selectedNames.length,
      }"
    >
      <b-input-group :class="{ 'cursor-pointer': !isDisabledInput }" @click="toggleDropdown">
        <b-input-group-prepend
          class="user-select-none"
          :class="{ focus: (isFocused || selectedNames.length) && !isDisabledInput }"
        >
          <span @click="handleInput">{{ label }}</span>
        </b-input-group-prepend>
        <b-form-input
          :class="{ 'cursor-pointer': !isDisabledInput }"
          :id="id"
          :ref="id"
          :disabled="disabled"
          v-model="selectedNames"
          placeholder=""
          @blur="isFocused = false"
          @focus="isFocused = true"
          autocomplete="off"
        >
        </b-form-input>
        <b-input-group-append>
          <b-icon-chevron-down :class="{ rotate180: showDropdown }" />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <div class="dropdown-with-checkboxes__dropdown" :class="{ show: showDropdown }">
      <div class="dropdown-checkboxes__action" @click.stop="clear">
        {{ $t('buttonLabels.clear') }}
      </div>
      <div class="dropdown-checkboxes">
        <div class="dropdown-checkboxes__item" @click="select">
          <RadioButton
            :checked="isSelectedAll"
            :isTypeCheckBox="true"
            size="sm"
            @change="select"
            @click.native.prevent
          />
          <div class="dropdown-checkboxes__label">
            {{ type === 'courses' ? $t('label.allCourses') : $t('label.allTasks') }}
          </div>
        </div>

        <template v-if="type === 'courses'">
          <div
            v-for="item in courses"
            :key="item.id"
            class="dropdown-checkboxes__item"
            @click="toggleCourse(item.id)"
          >
            <RadioButton
              :checked="isCourseSelected(item.id)"
              :isTypeCheckBox="true"
              size="sm"
              @change="toggleCourse(item.id)"
              @click.native.prevent
            />
            <div class="dropdown-checkboxes__label">
              {{ item.courseName || $t('label.courseWithoutName') }}
            </div>
          </div>
        </template>

        <template v-if="type === 'tasks'">
          <div v-for="course in selectedCourses" :key="course.id">
            <div class="dropdown-checkboxes__course">
              {{ course.courseName || $t('label.courseWithoutName') }}
            </div>
            <div
              v-for="item in course.tasks"
              :key="item._id"
              class="dropdown-checkboxes__item"
              @click="toggleTask(item._id)"
            >
              <RadioButton
                :checked="isTaskSelected(item._id)"
                :isTypeCheckBox="true"
                size="sm"
                @change="toggleTask(item._id)"
                @click.native.prevent
              />
              <div class="dropdown-checkboxes__label">
                {{ item.name }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/Buttons/RadioButton.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DropdownWithCheckBoxes',
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSelectedAllCourses: {
      type: Boolean,
      default: false,
    },
    isSelectedAllTasks: {
      type: Boolean,
      default: false,
    },
    showDropdown: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },
    toggleCourse: {
      type: Function,
      default: () => {},
    },
    toggleCourses: {
      type: Function,
      default: () => {},
    },

    toggleTasks: {
      type: Function,
      default: () => {},
    },
    toggleTask: {
      type: Function,
      default: () => {},
    },

    selectedCourseIds: {
      type: Array,
      default: () => [],
    },
    tasks: {
      type: Array,
      default: () => [],
    },
    selectedCourses: {
      type: Array,
      default: () => [],
    },
    selectedTaskIds: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    RadioButton,
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    ...mapGetters({ courses: 'chats/getFilteredCourses' }),
    isSelectedAll() {
      if (this.type === 'tasks') {
        if (!this.tasks.length) {
          return false;
        }
        return this.isSelectedAllTasks;
      }

      return this.isSelectedAllCourses;
    },
    selectedNames() {
      if (this.type === 'tasks') {
        if (!this.tasks.length) {
          return '';
        }
        return this.tasks
          .reduce((acc, curr) => {
            if (!curr.name?.length) {
              acc.push(this.$t('label.taskWithoutName'));
            } else {
              acc.push(curr.name);
            }
            return acc;
          }, [])
          .join(', ');
      }
      if (!this.courses.length) {
        return '';
      }
      if (this.type === 'courses') {
        if (this.isSelectedAll) {
          return this.$t('label.allCourses');
        }
        return this.courses
          .reduce((acc, curr) => {
            if (!this.selectedCourseIds.includes(curr.id)) {
              return acc;
            }
            if (!curr.courseName.length) {
              acc.push(this.$t('label.courseWithoutName'));
            } else {
              acc.push(curr.courseName);
            }

            return acc;
          }, [])
          .join(', ');
      }
      return '';
    },
    isCourseSelected() {
      return (id) => this.selectedCourseIds.includes(id);
    },
    isTaskSelected() {
      return (id) => this.selectedTaskIds.includes(id);
    },
    isDisabledInput() {
      return !this.selectedCourses.length && this.type === 'tasks';
    },
  },
  methods: {
    toggleDropdown() {
      if (this.isDisabledInput) {
        return;
      }
      this.$emit('update:showDropdown', !this.showDropdown);
    },
    select() {
      if (this.type === 'courses') {
        this.toggleCourses(!this.isSelectedAllCourses);
      }
      if (this.type === 'tasks') {
        this.toggleTasks(!this.isSelectedAllTasks);
      }
    },
    clear() {
      if (this.type === 'courses') {
        this.toggleCourses(false);
      }
      if (this.type === 'tasks') {
        this.toggleTasks(false);
      }
    },
    handleInput() {
      if (!this.isFocused) {
        this.isFocused = true;
        this.$nextTick(() => {
          this.$refs[this.id].focus();
        });
      }
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>
