<!-- eslint-disable max-len -->
<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path
      d="M4.66663 6.66675L7.99996 10.0001L11.3333 6.66675"
      stroke="#FAA433"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
    />
    <path d="M8 10V2" stroke="#FAA433" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
};
</script>
